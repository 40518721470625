import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 42, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.relativePath === props.filename;
      });
      if (!image) {
        return null;
      }

      const fluidImages = image.node.childImageSharp.fluid;
      return (
        <div className="image-block">
          <img
            src={fluidImages.src}
            className="preview"
            alt={props.alt}
            title={props.title}
            onClick={props.onClick}
          />
        </div>
      );
    }}
  />
);

export default Image;
